import styled from "styled-components"
import { device } from "breakpoints"
import { Search } from "components/Search"
import { MainColumn } from "components/MainColumn"
import { ThreeColumnTeaser } from "components/ThreeColumnGrid/style"
import { Title, Excerpt, ImageContainer } from "components/Teaser/style"

export const SearchTeaser = styled(ThreeColumnTeaser)`
  ${ImageContainer} {
    margin-bottom: 20px;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
  }

  ${Excerpt} {
    display: block;
    font-weight: 300;
    color: #5a5a5a;
    font-size: 14px;

    @media ${device.desktop} {
      font-size: 16px;
    }
  }

  ${Title} {
    font-size: 28px;
    margin-bottom: 10px;
    @media ${device.tablet} {
      font-size: 22px;
    }
    @media ${device.desktop} {
      font-size: 24px;
    }
  }
`
export const SearchPageContainer = styled(MainColumn)`
  margin-top: 35px;
  margin-bottom: 100px;
  @media ${device.desktop} {
    margin-top: 43px;
  }

  @media ${device.large} {
    margin-top: 68px;
  }
`
export const SearchInput = styled(Search)`
  margin-bottom: 20px;
  height: auto;

  @media ${device.large} {
    margin-bottom: 30px;
  }
  input {
    height: auto;
    font-size: 24px;
    color: ${props => props.theme.blue};
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 100%;
    ::placeholder {
      color: ${props => props.theme.blue};
    }

    @media ${device.desktop} {
      font-size: 46px;
      padding-bottom: 30px;
    }

    @media ${device.large} {
      font-size: 50px;
      padding-bottom: 30px;
    }
  }

  button {
    width: 24px;
    height: 24px;
    top: 10px;

    @media ${device.desktop} {
      width: 30px;
      height: 30px;
      top: 27px;
    }

    @media ${device.large} {
      top: 40px;
    }
  }
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-bottom: 28px;

  @media ${device.desktop} {
    font-size: 16px;
    margin-bottom: 45px;
  }
  h4 {
    font-size: inherit;
    color: ${props => props.theme.blue};
    font-weight: 500;
  }
`

export const FiltersList = styled.ul`
  list-style: none;
  display: flex;
  margin-left: 12px;
`

export const FilterItem = styled.li`
  /* margin-right: 15px; */
  position: relative;
  :before {
    content: "";
    width: calc(100% - 19px);
    height: 2px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    transition: 100ms;
    opacity: ${props => (props.selected ? 1 : 0)};
    background: ${props => props.theme.green};
  }
  button {
    padding: 5px 11px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;

    color: #5a5a5a;
  }
`
