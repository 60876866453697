import React, { useState, useEffect } from "react"
import { FilterItem } from "./style"
const Filter = ({ item, filter, setFilter }) => {
  const [selected, setSelected] = useState(false)
  const toggleSelect = () => {
    setFilter(item)
  }

  useEffect(() => {
    if (filter === item) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [filter])
  return (
    <FilterItem selected={selected}>
      <button onClick={() => toggleSelect()}>{item}</button>
    </FilterItem>
  )
}

export default Filter
