import React, { useState, useEffect, useCallback } from "react"
import {
  SearchPageContainer,
  SearchInput,
  SearchFiltersContainer,
  FiltersList,
  SearchTeaser,
} from "./style"
import Filter from "./Filter"
import algoliasearch from "algoliasearch"
import useDidMountEffect from "../../hooks/didMount"
import { FlexRow, FlexCol, FlexGrid } from "components/FlexGrid"
import { LoadMore } from "../LoadMore"

const items = ["All pages", "Work", "Stories"]

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const SearchPage = ({ defaultPosts }) => {
  const [loadMore, setLoadMore] = useState(true)
  const [searchQuery, setSearchQuery] = useState("")
  const [filter, setFilter] = useState("All pages")
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(defaultPosts)

  const findResults = useCallback(() => {
    index
      .search(searchQuery, {
        page: currentPage - 1,
        hitsPerPage: 9,
        facetFilters: [
          filter === "Work"
            ? [`type: casestudy`]
            : filter === "Stories"
            ? [`type: post`]
            : [`type: casestudy`, `type: post`],
        ],
      })
      .then(data => {
        const mergedHits = [...currentPosts, ...data.hits]
        setCurrentPosts(currentPage === 1 ? data.hits : mergedHits)

        if (currentPage >= data.nbPages) {
          setLoadMore(false)
        } else {
          setLoadMore(true)
        }
      })
  }, [currentPage, searchQuery, filter])

  useDidMountEffect(() => {
    findResults()
  }, [findResults])

  useEffect(() => {
    setTimeout(() => {
      setCurrentPage(1)
    }, 50)
  }, [searchQuery, filter])
  return (
    <SearchPageContainer>
      <SearchInput
        onChange={e => setSearchQuery(e.target.value)}
        searchQuery={searchQuery}
        placeholder="Start typing to search..."
      />

      <SearchFiltersContainer>
        <h4>Filter by:</h4>
        <FiltersList>
          {items.map((item, index) => {
            return (
              <Filter
                setFilter={setFilter}
                filter={filter}
                key={index}
                item={item}
              />
            )
          })}
        </FiltersList>
      </SearchFiltersContainer>

      <FlexGrid fullWidth gap={[40, 30, 60]} rowGap={[40, 30, 40]}>
        <FlexRow>
          {currentPosts.map((page, index) => {
            if (page.featuredImage) {
              page.featuredImage.aspectRatio = 1
            }

            return (
              <FlexCol key={index} xs={12} sm={4}>
                <SearchTeaser
                  title={page.title}
                  img={page.featuredImage}
                  titleLink={page.link}
                  excerpt={page.excerpt}
                />
              </FlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
      {loadMore && (
        <LoadMore handleLoadMore={() => setCurrentPage(currentPage + 1)} />
      )}
    </SearchPageContainer>
  )
}

export default SearchPage
