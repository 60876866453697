import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SearchPage } from "components/SearchPage"
import { useStaticQuery, graphql } from "gatsby"

const PageTemplate = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        contentNodes (
          first: 9
          where: { contentTypes: [CASESTUDY, POST] orderby: { field: DATE, order: DESC } }
        ) {
          nodes {
            ... on WPGraphQL_Casestudy {
              title
              excerpt
              link
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on WPGraphQL_Post {
              title
              excerpt
              link
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)

  const defaultPosts = data.wpgraphql.contentNodes.nodes

  return (
    <Layout>
      <SEO title="Internal Page" />
      <SearchPage defaultPosts={defaultPosts} />
    </Layout>
  )
}

export default PageTemplate
